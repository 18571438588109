import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { motion } from 'framer-motion'

import { Layout } from '../components'
import { container, padding, colours, type, bgImage } from '../styles/global'
import { media } from '../styles/utils'
import { getVideoURL } from '../utils'
import ReactPlayer from 'react-player'

const Main = ({ pageContext, ...props }) => {
	console.log(pageContext)
	
	// Video

    const renderVideo = () => {
        if (!pageContext.acf.video) return

        return (
            <Video
                controls
                playsinline
                onReady={() => setVideoLoaded(true)}
                url={getVideoURL(pageContext.acf.video)}
                width={'100%'}
                height={'100%'}
            />
        )
    }

    // Grid

    const renderGrid = () => {
        if (!pageContext.acf.gallery) return

        const items = pageContext.acf.gallery.map((item, i) => {
            return (
				<Wrapper>
					{item.image && (
						<Image
                        delay={1000}
                        key={item.image.sizes.medium2}
                        src={item.image.sizes.medium2}
                    >
                        {(src, loading) => {
                            return (
                                <img
                                    src={src}
                                    style={{
                                        opacity: loading ? 0 : 1,
                                    }}
                                />
                            )
                        }}
                    </Image>
					)}
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: item.caption,
                        }}
                    />
                </Wrapper>
            )
        })

        return <Grid>{items}</Grid>
    }

    return (
        <Layout meta={pageContext && pageContext.acf.seo} hideHeader={true}>
            <Container>
                <Header>
                    <Title>{pageContext.title}</Title>
                    <Close as={Link} to="/">
                        Close
                    </Close>
                </Header>
                <Featured>
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: pageContext.acf.description,
                        }}
					/>

					{/*Image*/}
					{pageContext.acf.header_video_or_image == 'image' && pageContext.acf.image && (
                        <Wrapper>
                            <Image
                                key={pageContext.acf.image.sizes.medium2}
                                src={pageContext.acf.image.sizes.medium2}
                            >
                                {(src, loading) => {
                                    return (
                                        <BGImage
                                            image={src}
                                            style={{
                                                opacity: loading ? 0 : 1,
                                            }}
                                        />
                                    )
                                }}
                            </Image>
                        </Wrapper>
					)}

					{/*Video*/}
                    {pageContext.acf.header_video_or_image == 'video' && pageContext.acf.video && (
                        <Wrapper>
                            <Video
								playing
								muted
								loop
								playsinline
								url={pageContext.acf.video}
								width={'100%'}
								height={'100%'}
							/>
                        </Wrapper>
                    )}
					
					{/*Video Embed*/}
                    {pageContext.acf.header_video_or_image == 'video-embed' && pageContext.acf.video_embed && (
                        <Wrapper>
                            <Video
								controls
								playsinline
								onReady={() => setVideoLoaded(true)}
								url={getVideoURL(pageContext.acf.video_embed)}
								width={'100%'}
								height={'100%'}
							/>
                        </Wrapper>
                    )}
                </Featured>

                {renderGrid()}
            </Container>
        </Layout>
    )
}

// Shared

const Title = styled.h1``
const Close = styled.div``
const Text = styled.div``
const Wrapper = styled.div``
const Video = styled(ReactPlayer)``

// Base

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
    width: 100%;
    ${padding}
    padding-top: 20px;
    padding-bottom: 20px;

    ${(props) => {
        if (props.theme.main == 'dark')
            return css`
                color: ${colours.white};
            `
    }}
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;

    ${Title} {
        font-size: 132px;
        line-height: 0.9;
		text-align: left;
		font-weight: 500;

        ${media.tablet`
			padding-bottom: 20px;
			font-size: 80px;
		`}

        ${media.tablet`
			font-size: 40px;
		`}
    }

    ${Close} {
        ${type.body}
        cursor: pointer;
    }
`

// Featured

const Featured = styled.div`
    display: flex;
    justify-content: space-between;

    ${media.tablet`
		flex-direction: column;
	`}

    ${Text} {
		flex: 0 1 20%;

        ${media.tablet`
			 flex: 0 1 30%;
		`}

        ${media.phone`
			flex: 0 1 100%;
		`}

    }

    ${Wrapper} {
        position: relative;
        flex: 0 1 80%;
        min-height: 74vh;

        ${media.tablet`
			 flex: 0 1 70%;
		`}

		${media.phone`
			flex: 0 1 100%;
			min-height: 500px;
		`}

        ${BGImage} {
			height: 100%;
			
			${media.phone`
				min-height: 500px;
			`}
        }
    }
`

// Grid

const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    ${Wrapper} {
        position: relative;
        flex: 1 1 calc(50% - 10px);
        max-width: calc(50% - 10px);
        padding: 20px 0 0;
        box-sizing: border-box;

        ${media.phone`
			flex: 1 1 100%;
			max-width: 100%;
		`}

        img {
            width: 100%;
            object-fit: contain;
        }
    }
`

export default Main
